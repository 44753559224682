import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import * as styles from '../../styles/css/map.module.css'

const Marker = ({ text }) => (
  <div className={styles.map}>
    <img src="/images/contact/pin.png" alt={text} width="25px;" />
    <div className={styles.box}><span className={styles.title}>{text}</span></div>
  </div>
);

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 37.4982296,
      lng: 127.0342741
    },
    location: {
      title: 'AlphaCode',
      lat: 37.4982296,
      lng: 127.0342741
    },
    zoom: 16
  };

  render() {
    
    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBnboUfAhXcuCUZw0lQPy1L-16NetDzxQw', libraries: 'places' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}       
        >
          <Marker
            text={this.props.location.title}
            lat={this.props.location.lat}
            lng={this.props.location.lng}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMap;